<template>
    <div>
        <div class="content">
            <el-form :model="dataForm" ref="cmdRealDataForm" label-width="160px" :inline="true" size="mini">
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="蜂鸣器">
                            <el-radio-group disabled v-model="dataForm.silenceAndBuzzer">
                                <el-radio label="1">启用</el-radio>
                                <el-radio label="0">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="过载旁路">
                            <el-radio-group disabled v-model="dataForm.overloadBypass">
                                <el-radio label="1">启用</el-radio>
                                <el-radio label="0">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="过载重启">
                            <el-radio-group disabled v-model="dataForm.overloadRestart">
                                <el-radio label="1">启用</el-radio>
                                <el-radio label="0">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="过温重启">
                            <el-radio-group disabled v-model="dataForm.overTemperatureRestart">
                                <el-radio label="1">启用</el-radio>
                                <el-radio label="0">禁用</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="交流输出额定电压">
                            <el-input disabled v-model="dataForm.acOutputVoltage"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="交流输出额定频率">
                            <el-input disabled v-model="dataForm.acOutputFrequency"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="12">
                        <el-form-item label="交流输出额定电流">
                            <el-input disabled v-model="dataForm.maxAcChargingCurrent"/>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="当前最大充电电流">
                            <el-input disabled v-model="dataForm.maxChargingCurrent"/>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="输出源优先级">
                            <el-radio-group disabled v-model="dataForm.outputSourcePriority">
                                <el-radio label="1">光伏优先</el-radio>
                                <el-radio label="2">SBU优先</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="输入电压范围">
                            <el-radio-group disabled v-model="dataForm.acInputVoltageRange">
                                <el-radio label="0">设备(90V~280V)</el-radio>
                                <el-radio label="1">不间断电源(170v~280V)</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="充电器源优先级">
                            <el-radio-group disabled v-model="dataForm.chargerSourcePriority">
                                <el-radio label="0">市电优先</el-radio>
                                <el-radio label="1">光伏优先</el-radio>
                                <el-radio label="2">市电+光伏</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row>
                    <el-col :span="24">
                        <el-form-item label="输出模式">
                            <el-radio-group disabled v-model="dataForm.outputMode">
                                <el-radio label="0">单机输出</el-radio>
                                <el-radio label="1">并机输出</el-radio>
                                <el-radio label="2">三相输出1相</el-radio>
                                <el-radio label="3">三相输出2相</el-radio>
                                <el-radio label="4">三相输出3相</el-radio>
                            </el-radio-group>
                        </el-form-item>
                    </el-col>
                </el-row>
            </el-form>
        </div>
        <div class="btn-box">
            <el-button @click="submit" type="primary" size="mini">恢复出厂设置</el-button>
        </div>
    </div>
</template>

<script>
import loading from '@/utils/cmdLoading';
import mqttService from '@/api/mqtt-service';
import message from '@/utils/message';
import global from '@/components/Global';

export default {

    name: 'control-parameter-default',
    data() {

        return {

            command: {

                commandCodeR: 'get_default_setting_value',
                commandCodeW: 'set_control_parameter_to_default'
            },
            deviceSn: '',
            dataForm: {

                acOutputVoltage: '',
                acOutputFrequency: '',
                maxAcChargingCurrent: '',
                batteryUnderVoltage: '',
                chargingFloatVoltage: '',
                chargingBulkVoltage: '',
                batteryRechargeVoltage: '',
                maxChargingCurrent: '',
                acInputVoltageRange: '',
                outputSourcePriority: '',
                chargerSourcePriority: '',
                batteryType: '',
                silenceAndBuzzer: '',
                powerSaving: '',
                overloadRestart: '',
                overTemperatureRestart: '',
                lcdBacklight: '',
                primarySourceInterrupt: '',
                faultCodeRecord: '',
                overloadBypass: '',
                lcdTimeoutEscape: '',
                outputMode: '',
                batteryReDischargeVoltage: '',
                pvOkCondition: '',
                pvPowerBalance: '',
                maxChargingTime: '',
                operationLogic: '',
            }
        }
    },
    methods: {

        initPage(deviceSn) {

            this.deviceSn = deviceSn;
            this.cmdLoading = loading.loading('设备出厂参数读取中...', 30, function () {

                mqttService.disconnectMqtt();
            });
            let option = {

                loading: this.cmdLoading,
                data: {

                    deviceSn: this.deviceSn,
                    commandCode: this.command.commandCodeR
                },
                mqttMessageCallback: res => {

                    if (res.code === global.response_status_success_obj) {

                        this.initSuccessCallBack(res.data);
                    } else {

                        message.error('指令下发失败');
                    }
                }
            };
            mqttService.sendEsaioBusinessCommand(option);
        },
        initSuccessCallBack(data) {

            this.dataForm = data;
            this.dataForm.acOutputVoltage = data.acOutputVoltage + 'V';
            this.dataForm.acOutputFrequency = data.acOutputFrequency + 'Hz';
            this.dataForm.maxAcChargingCurrent = data.maxAcChargingCurrent + 'A';
            this.dataForm.batteryUnderVoltage = data.batteryUnderVoltage + 'V';
            this.dataForm.chargingFloatVoltage = data.chargingFloatVoltage + 'V';
            this.dataForm.chargingBulkVoltage = data.chargingBulkVoltage + 'V';
            this.dataForm.batteryRechargeVoltage = data.batteryRechargeVoltage + 'V';
            this.dataForm.maxChargingCurrent = data.maxChargingCurrent + 'A';
            this.dataForm.batteryReDischargeVoltage = data.batteryReDischargeVoltage + 'V';
        },
        submit() {

            this.$confirm('点击确定后将下发当前页面上所有参数', '确定恢复出厂设置吗？', {

                closeOnClickModal: false,
                type: 'warning'
            }).then(() => {

                this.cmdLoading = loading.loading('恢复出厂设置指令下发中...', 30, function () {

                    mqttService.disconnectMqtt();
                });
                let option = {

                    loading: this.cmdLoading,
                    data: {

                        deviceSn: this.deviceSn,
                        commandCode: this.command.commandCodeW
                    },
                    mqttMessageCallback: res => {

                        if (res.code === global.response_status_success_obj) {

                            message.success('恢复出厂设置指令下发成功');
                        } else {

                            message.error('指令下发失败');
                        }
                    }
                };
                mqttService.sendEsaioBusinessCommand(option);
            });
        }
    }
}
</script>

<style lang="scss" scoped>

.content {

    height: 490px;
    overflow-y: auto;
    margin: 10px;
}

.btn-box {

    text-align: center;
}
</style>
